import React from "react"
import {facebookUrl, tiktokUrl, instagramUrl, discordUrl} from '../utils/config'

const Footer = () => {
    return (
        <div id="footer">
            <p>
                No-Olvidar, 2021 {` `}
                <a href={facebookUrl} target="_blank" rel="noreferrer" ><i class="fab fa-facebook" id="footer-icon">{``}</i></a> {` `}
                <a href={tiktokUrl} target="_blank" rel="noreferrer" ><i class="fab fa-tiktok" id="footer-icon">{``}</i></a> {` `}
                <a href={instagramUrl} target="_blank" rel="noreferrer" ><i class="fab fa-instagram" id="footer-icon">{``}</i></a> {` `}
                <a href={discordUrl} target="_blank" rel="noreferrer" ><i class="fab fa-discord" id="footer-icon">{``}</i></a> {` `}
            </p>
            <p>
                Textos publicados bajo licencia Creative Commons Reconocimiento–No Comercial a menos que se especifique lo contrario
                {` | `} <a href="https://farol.no-olvidar.com/pages/terminos-y-condiciones" target="_blank" rel="noreferrer">Términos y condiciones</a>
                {` | `} <a href="https://no-olvidar.com/aviso-de-privacidad" target="_blank" rel="noreferrer">Aviso de privacidad</a>
            </p>
        </div>
    )
}

export default Footer